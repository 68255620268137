<template>
  <loading v-if="$_.isEmpty(user)" />

  <div v-else-if="isAgent">
    <b-field v-if="isAdmin && !isResellerUser" label="User role">
      <user-role-select :user-id="userId" />
    </b-field>

    <button
      v-if="!isResellerUser"
      class="button is-medium is-stretched"
      @click="showAddCreditModal"
    >
      <span class="is-size-6">Grant account credit</span>
    </button>

    <button
      v-if="!isResellerUser"
      class="button is-medium is-stretched has-margin-top-50"
      @click="openLinkReferrerModal"
    >
      <span class="is-size-6">Manually link referrer</span>
    </button>

    <button
      v-if="isAdmin"
      class="button is-medium is-stretched has-margin-top-50"
      @click="showAddPriceTierModal"
    >
      <span class="is-size-6">Manage custom discounts</span>
    </button>

    <button
      v-if="isAdmin && !isResellerUser && !isReseller"
      :disabled="processing.reseller"
      class="button is-medium is-stretched is-outlined has-margin-top-50"
      :class="{ 'is-loading': processing.reseller }"
      @click="makeReseller"
    >
      <span class="is-size-6">Make user a reseller</span>
    </button>

    <button
      class="button is-medium is-stretched is-outlined has-margin-top-50"
      @click="adminChangeEmail"
    >
      <span class="is-size-6">Change email</span>
    </button>

    <button
      v-if="isAdmin && !user.isDisabled"
      class="button is-medium is-stretched is-danger is-outlined has-margin-top-50"
      @click="disableAccount"
    >
      <span class="is-size-6">Disable account</span>
    </button>

    <button
      v-if="isAdmin && user.isDisabled"
      class="button is-medium is-stretched is-success is-outlined has-margin-top-50"
      :class="{ 'is-loading': processing.enable }"
      :disabled="processing.enable"
      @click="enableAccount"
    >
      <span class="is-size-6">Enable account</span>
    </button>
  </div>
</template>

<script>
export default {
  name: "UserStaffControls",
  components: {
    "user-role-select": () => import("@shared/account/_userRoleSelect")
  },
  props: {
    userId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      processing: {
        reseller: false
      }
    };
  },
  computed: {
    user() {
      return this.$store.getters["user/user"](this.userId);
    },
    isAgent() {
      return this.$store.getters["user/isAgent"]();
    },
    isAdmin() {
      return this.$store.getters["user/isAdmin"]();
    },
    isResellerUser() {
      return this.$store.getters["user/isResellerUser"](this.userId);
    },
    isReseller() {
      return this.$store.getters["user/isReseller"](this.userId);
    }
  },
  created() {
    this.$store.dispatch("user/observeUser", { userId: this.userId });
  },
  beforeDestroy() {
    this.$store.dispatch("user/unobserveUser", { userId: this.userId });
  },
  methods: {
    openLinkReferrerModal() {
      this.$modal.open({
        component: () => import("@shared/account/_linkReferrerModal"),
        parent: this,
        width: 460,
        hasModalCard: true,
        canCancel: ["escape", "outside"],
        props: {
          userId: this.userId
        }
      });
    },
    showAddCreditModal() {
      this.$modal.open({
        component: () => import("@shared/account/_addCreditModal"),
        parent: this,
        width: 460,
        hasModalCard: true,
        canCancel: ["escape", "outside"],
        props: {
          userId: this.userId
        }
      });
    },
    showAddPriceTierModal() {
      this.$modal.open({
        component: () => import("@shared/account/_listPriceTiersModal"),
        parent: this,
        width: 700,
        hasModalCard: true,
        canCancel: ["escape", "outside"],
        props: {
          userId: this.userId
        }
      });
    },
    makeReseller() {
      const modal = this.$modal.open({
        component: () => import("@components/single-input-modal"),
        parent: this,
        width: 460,
        hasModalCard: true,
        canCancel: ["escape", "outside"],
        props: {
          title: "Reseller name",
          submitText: "Make reseller"
        },
        events: {
          submit: value => {
            this.processing.reseller = true;

            this.$store
              .dispatch("reseller/makeReseller", {
                userId: this.userId,
                name: value
              })
              .then(() => {
                this.processing.reseller = false;
                modal.close();
              })
              .catch(() => {
                this.processing.reseller = false;
                this.$toast.open({
                  message: `There was a problem making this user reseller`,
                  position: "is-bottom",
                  type: "is-danger",
                  queue: false
                });
              });
          }
        }
      });
    },
    disableAccount() {
      const confirm = this.$confirm.open({
        parent: this,
        props: {
          autoClose: false,
          action: "Disable account",
          message: `Are you sure you want to disable this user's account? This will terminate any active sessions they have.`
        },
        canCancel: [],
        events: {
          confirmed: async () => {
            await this.confirmedEnable({
              userId: this.userId,
              enable: false
            }).finally(() => {
              confirm.close();
            });
          }
        }
      });
    },
    async enableAccount() {
      this.$set(this.processing, "enable", true);
      await this.confirmedEnable({ userId: this.userId, enable: true });
      this.processing.enable = false;
    },
    confirmedEnable({ userId, enable }) {
      return this.$store
        .dispatch("user/enable", { userId, enable })
        .then(result => {
          this.$toast.open({
            message: result.message,
            duration: 6000
          });
        })
        .catch(error => {
          this.$toast.open({
            message: error.message,
            type: "is-danger"
          });
        });
    },
    adminChangeEmail() {
      if (!this.isAdmin) return;
      const modal = this.$modal.open({
        component: () => import("@src/components/single-input-modal"),
        canCancel: false,
        width: 480,
        props: {
          title: "Change email",
          submitText: "Change",
          placeholder: "example@example.com"
        },
        events: {
          submit: email => {
            this.$store
              .dispatch("auth/adminChangeEmail", {
                userId: this.userId,
                email
              })
              .then(() => {
                this.$toast.open({
                  message: `Email changed to ${email}`
                });
                modal.close();
              })
              .catch(error => {
                modal.close();
                this.$toast.open({
                  message: error.message,
                  type: "is-danger"
                });
              });
          }
        }
      });
    }
  }
};
</script>
